define("discourse/plugins/disraptor/discourse/lib/generate-route-id", ["exports", "discourse/lib/hash"], function (_exports, _hash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateRouteId = generateRouteId;
  /**
   * The algorithm for generating a route’s database ID based on its defining properties.
   *
   * @param {string} routeRequestMethod
   * @param {string} routeSourcePath
   * @returns {number} a route’s ID.
   */
  function generateRouteId(routeRequestMethod, routeSourcePath) {
    return (0, _hash.hashString)(routeSourcePath + routeRequestMethod) >>> 0;
  }
});