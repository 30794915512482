define("discourse/plugins/disraptor/discourse/models/disraptor/route", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Has to be implemented for `../controllers/admin-plugins-disraptor.js.es6` in order to use
   * Discourse’s store properly.
   *
   * When extending `RestModel`, the methods `createProperties` and `updateProperties` need to be
   * implemented.
   *
   * `RestModel` is implemented in
   * `$discourseDir/app/assets/javascripts/discourse/models/rest.js.es6`.
   */
  var _default = _exports.default = _rest.default.extend({
    /**
     * Required when sending POST requests via Discourse’s store
     */
    createProperties() {
      return this.getProperties('sourcePath', 'targetUrl', 'requestMethod');
    },
    /**
     * Required when sending PUT requests via Discourse’s store
     */
    updateProperties() {
      return this.createProperties();
    }
  });
});